// MEDIA QUERY BREAK POINTS
// calculation -  device-width/16px = break point in em
$bp-laptop-L: 90em; // 1440px
$bp-laptop-M: 64em; // 1024px
$bp-laptop-S: 62.5em; // 1000px
$bp-tab: 48em; // 800px
$bp-mobile: 37.5em; // 600px

.icon-water {
  // @extend .ebr-icon;
  background: url("/assets/images/drop.svg") no-repeat left center;
}

.icon-energy {
  // @extend .ebr-icon;
  background: url("/assets/images/fire.svg") no-repeat left center;
}

.icon-waste {
  // @extend .ebr-icon;
  background: url("/assets/images/recycle.svg") no-repeat left center;
}

.icon-cost-benefit {
  // @extend .ebr-icon;
  background: url("/assets/images/cost-&-benefits.svg") no-repeat left center;
}

.icon-asset-protection {
  // @extend .ebr-icon;
  background: url("/assets/images/asset_protection.svg") no-repeat left center;
}

.icon-quality {
  // @extend .ebr-icon;
  background: url("/assets/images/quality.svg") no-repeat left center;
}

.icon-labor-productivity {
  // @extend .ebr-icon;
  background: url("/assets/images/labor_productivity.svg") no-repeat left center;
}

.icon-location-productivity {
  // @extend .ebr-icon;
  background: url("/assets/images/location_productivity.svg") no-repeat left
    center;
}

.icon-reliability {
  // @extend .ebr-icon;
  background: url("/assets/images/reliability.svg") no-repeat left center;
}

.icon-brand {
  // @extend .ebr-icon;
  background: url("/assets/images/brand.svg") no-repeat left center;
}

.icon-product-optimization {
  // @extend .ebr-icon;
  background: url("/assets/images/product_optimization.svg") no-repeat left
    center;
}

.icon-raw-material-optimization {
  // @extend .ebr-icon;
  background: url("/assets/images/raw_material.svg") no-repeat left center;
}

.icon-delighted-guests {
  // @extend .ebr-icon;
  background: url("/assets/images/delighted_guests.svg") no-repeat left center;
}

.icon-capacity {
  // @extend .ebr-icon;
  background: url("/assets/images/capacity.svg") no-repeat left center;
}

.icon-human-safety {
  // @extend .ebr-icon;
  background: url("/assets/images/human_safety.svg") no-repeat left center;
}

.icon-water-safety {
  // @extend .ebr-icon;
  background: url("/assets/images/water_safety.svg") no-repeat left center;
}

.icon-regulatory-compliance {
  // @extend .ebr-icon;
  background: url("/assets/images/regular_compliance.svg") no-repeat left center;
}

// FONT FAMILY
$font-family: Roboto, "Helvetica Neue", sans-serif;

// CSS GLOBAL RESET
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}
.no-scroll {
  overflow: hidden;
}
html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

  @media only screen and (max-width: $bp-laptop-M) {
    font-size: 50%;
  }

  @media only screen and (max-width: $bp-laptop-S) {
    font-size: 40%;
  }
}

body {
  font-family: $font-family;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Loader container to align spinner and text */
.loader-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between spinner and text */
}

/* Spinner styling */
.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  /* Apply the animation */
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Loading text */
.loading-text {
  font-size: 16px;
  color: #007bff;
}

.libTotalValueWrapper {
  .customer-main-details-left {
    grid-column: span 3 !important;
    @media screen and (min-width: 320px) and (max-width: 1400px) {
      width: 100%;
    }
  }

  .customer-main-details-right {
    grid-column: span 9 !important;
    @media screen and (min-width: 320px) and (max-width: 1400px) {
      width: 100%;
    }
    @media screen and (min-width: 1401px) and (max-width: 1500px) {
      padding-left: 20px !important;
    }
    @media screen and (min-width: 1500px) and (max-width: 2560px) {
      padding-left: 6px !important;
    }
  }

  .customer-main-details-wrapper {
    display: grid !important;
    grid-template-columns: repeat(12, 1fr) !important;

    @media screen and (min-width: 320px) and (max-width: 1200px) {
      gap: 32px !important;
      grid-template-columns: 1fr !important;
    }
    @media screen and (min-width: 1201px) and (max-width: 1400px) {
      gap: 44px !important;
      grid-template-columns: 1fr !important;
    }
    @media screen and (min-width: 1401px) and (max-width: 2560px) {
      gap: 0px !important;
    }
  }
}
